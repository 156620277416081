import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { MainSubStructure, NormalProgrammeComponent } from '../../../utils';
import { AddModal } from './addModal';
import { FinanceDetailStore } from './model';

const FinanceDetail = observer(() => {
  const store = useMemo(() => new FinanceDetailStore(), []);
  const { filterSet, mainSubStructureModel, statistics } = store;

  const mainDataList = [
    {
      title: '账户余额',
      value: statistics?.totalAmount || '--',
    },
    {
      title: '平台收入',
      value: statistics?.incomeAmount || '--',
    },
    {
      title: '平台支出',
      value: statistics?.payAmount || '--',
    },
  ];

  return (
    <div className="bg-[#F0F3F8] h-screen flex flex-col">
      <div className="bg-white pl-8 pr-8">
        <div className="pb-2 pt-6">
          <NormalProgrammeComponent store={filterSet} />
        </div>
      </div>

      <div className="flex justify-around pt-4 pb-4 mt-2 bg-white">
        {mainDataList.map((item) => {
          return (
            <div
              key={item.title + item.value}
              className="flex flex-col items-center"
            >
              <div className="text-primary text-20 font-600">{item.value}元</div>
              <div className="mt-2">{item.title}</div>
            </div>
          );
        })}
      </div>

      <div className="flex-1 bg-white mt-2 pt-2">
        <MainSubStructure store={mainSubStructureModel} />
      </div>

      <AddModal store={store} />
    </div>
  );
});
export default FinanceDetail;
